import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { API_KEY, CUSTOMERS_URL } from '../../utils';

import { getApiInstance } from '../../utils/helpers/api';

const customerServiceUrl =
    process.env?.REACT_APP_CUSTOMERS_API_URL ?? CUSTOMERS_URL;

const api = getApiInstance(customerServiceUrl);

const useCustomerClaimedRewards = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.claimed, userId],
        queryFn: async () => {
            const data = api
                .get(`customer/${userId}/claimed`)
                .then((res) => res.data);

            return data;
        },
        enabled: !!userId,
        ...option,
    });

const fetchCustomer = async (userId) => {
    const data = await api.get(`customer/${userId}`);

    return data;
};

const useCustomer = (userId, option) => {
    return useQuery({
        queryKey: [API_KEY.customer, userId],
        queryFn: async () => {
            return fetchCustomer(userId);
        },
        enabled: !!userId,
        ...option,
        throwOnError: true,
    });
};

const useFetchCustomer = () => {
    const [error, setError] = useState(null);

    const queryClient = useQueryClient();

    const fetchCustomer = async (userId) => {
        const queryKey = [API_KEY.customer, userId];
        try {
            const data = await queryClient.fetchQuery({
                queryKey,
                queryFn: async () => {
                    const res = await api.get(`customer/${userId}`);

                    return res;
                },
                staleTime: 60000, // 1 min
            });
            return data;
        } catch (error) {
            setError(error);
        }
    };

    return { fetchCustomer, error };
};

const useUpdateCustomer = (options) =>
    useMutation({
        mutationFn: async ({ body }) => {
            const response = await api.put('customer/profile', body);

            return response;
        },
        ...options,
    });

const useActivityHistory = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.activities, userId],
        queryFn: async () => {
            const data = api
                .get(`customer/${userId}/activities`)
                .then((res) => res.data);

            return data;
        },
        enabled: !!userId,
        ...option,
    });

const useGetActivityHistory = (options) =>
    useMutation({
        mutationFn: async ({ userId }) => {
            if (userId) {
                const response = await api.get(`customer/${userId}/activities`);
                return response;
            }
            return {};
        },
        ...options,
    });

const useCreateCustomer = (options) =>
    useMutation({
        mutationKey: 'create-customer',
        mutationFn: async ({ body, rCode }) => {
            const response = await api.post(
                `customer/create?ref=${rCode ?? null}`,
                body
            );
            return response;
        },
        onError: (err) => {
            console.error(err);
        },
        ...options,
        enabled: true,
    });

const useFetchCoupons = () => {
    const [error, setError] = useState(null);

    const queryClient = useQueryClient();

    const fetchCoupons = async (userId) => {
        const queryKey = [API_KEY.coupons, userId];
        try {
            const data = await queryClient.fetchQuery({
                queryKey,
                queryFn: async () => {
                    const res = await api.get(`customer/${userId}/coupons`);

                    return res;
                },
                staleTime: 60000, // 1 min
            });
            return data;
        } catch (error) {
            setError(error);
        }
    };

    return { fetchCoupons, error };
};

const useCoupons = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.coupons, userId],
        queryFn: async () => {
            const data = await api.get(`customer/${userId}/coupons`);

            return data;
        },
        enabled: !!userId,
        ...option,
    });

const useSendEmailVerification = (options) =>
    useMutation({
        mutationKey: ['send-email-verification'],
        mutationFn: async ({ userId }) => {
            const response = await api.post(
                `customer/${userId}/send-verification`
            );
            return response;
        },
        ...options,
    });

const useValidateEmail = (verificationCode, options) =>
    useQuery({
        queryKey: [API_KEY.emailVerification, verificationCode],
        queryFn: async () => {
            const response = await api.get(`validate/${verificationCode}`);
            return response;
        },
        ...options,
    });

const useToggleFavoriteReward = (options) =>
    useMutation({
        mutationKey: 'toggle-favorite-reward',
        mutationFn: async ({ userId, rewardId, customerId }) => {
            const response = await api.post(
                `customer/${userId}/favorite/reward`,
                {
                    customerId,
                    rewardId,
                }
            );
            return response;
        },
        onError: (err) => {
            console.error(err);
        },
        ...options,
        enabled: true,
    });

const customerQueries = {
    useCustomerClaimedRewards,
    useCustomer,
    useActivityHistory,
    useGetActivityHistory,
    useCreateCustomer,
    useFetchCoupons,
    fetchCustomer,
    useUpdateCustomer,
    useCoupons,
    useSendEmailVerification,
    useValidateEmail,
    useFetchCustomer,
    useToggleFavoriteReward,
};

export default customerQueries;
