import { Box, Center, Input, Text } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useViewport } from '../utils';

const DateInput = ({
    name,
    register,
    withLabel = false,
    placeholder,
    ...props
}) => {
    const [visibleLabel, setLabelVisible] = useState(true);
    const ref = useRef(null);
    const { isMobile } = useViewport();
    const today = new Date().toISOString().split('T')[0];

    return (
        <Box w="100%" position="relative">
            <Center
                position="absolute"
                top="4px"
                right="40px"
                bottom={visibleLabel ? '4px' : '40px'}
                left="4px"
                fontSize={visibleLabel ? 'sm' : isMobile ? '0' : 'xs'}
                backgroundColor="white"
                zIndex={3}
                onClick={() => {
                    ref.current.focus();
                }}
            >
                <Text w="100%" textAlign="left" pl="6px" color="gray.500">
                    {placeholder}
                </Text>
            </Center>
            <Input
                onFocus={() => setLabelVisible(false)}
                onBlur={() => setLabelVisible(true)}
                borderColor="gray.200"
                borderWidth="1px"
                borderRadius={1}
                w="100%"
                align="start"
                h={['40px', '40px', '40px', '60px']}
                px={2}
                fontSize={['small', 'small', 'small', 'medium']}
                pt={withLabel ? '30px' : '0px'}
                pb={withLabel ? '20px' : '0px'}
                name={name}
                {...props}
                {...register(name)}
                type="date"
                max={today}
                zIndex={2}
                ref={ref}
            />
        </Box>
    );
};

export default DateInput;
