import { CheckCircleIcon, TimeIcon, TriangleUpIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    Center,
    Flex,
    HStack,
    Heading,
    Icon,
    IconButton,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Progress,
    Spacer,
    Text,
    Tooltip,
    VStack,
    useDisclosure,
    useToast,
} from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useContext, useMemo, useState } from 'react';
import Countdown from 'react-countdown';
import { VideoModal } from './components/VideoModal';
import { UserContext } from './UserContext';

const secondsPerDay = 86400000;

const pad = (n) => {
    return n < 10 ? '0' + n : n;
};

const CardItem = ({
    image,
    title,
    daysLeft,
    points,
    progress,
    completionText,
    details,
    videoUrls,
    category,
    refetch,
    onOpen = () => {},
    onClose = () => {},
}) => {
    const toast = useToast();
    const curDate = useMemo(() => {
        return Date.now();
    }, []);

    const {
        isOpen: isVideoOpen,
        onOpen: onVideoOpen,
        onClose: onVideoClose,
    } = useDisclosure();

    const { user } = useContext(UserContext);
    const [showDetails, setShowDetails] = useState(false);
    const isTextLong = details && details.length > 180;

    const trimDetailsText = (details) => {
        return isTextLong ? `${details.substring(0, 180)}...` : details;
    };

    return (
        <Card
            w={['400px', 'auto', 'auto', 'auto']}
            m={[0, 2, 2, 2]}
            h={[showDetails ? '670px' : '540px']}
            minH={['540px', '540px', '500px', '540px']}
            _hover={{
                transform: 'scale(1.05)',
                boxShadow: 'lg',
            }}
        >
            <CardBody p={0}>
                <Box position="relative">
                    <Image
                        src={image}
                        w="100%"
                        h={300}
                        maxH={300}
                        objectFit="cover"
                        borderTopRadius="md"
                    />
                    {daysLeft > 0 && (
                        <HStack
                            bgColor="brand.red"
                            position="absolute"
                            p={2}
                            top={6}
                            left={6}
                        >
                            <TimeIcon color="white" />
                            <Countdown
                                date={curDate + secondsPerDay * daysLeft}
                                renderer={(props) => (
                                    <Text color="white" fontWeight={700}>
                                        {props.days}d {props.hours}h{' '}
                                        {pad(props.minutes)}m{' '}
                                        {pad(props.seconds)}s
                                    </Text>
                                )}
                            />
                        </HStack>
                    )}
                    {category === 'referral' && (
                        <Center position="absolute" right={6} top={6}>
                            <Button
                                size="md"
                                variant="green"
                                fontSize="18px"
                                borderRadius={2}
                                leftIcon={
                                    <Icon viewBox="0 0 24 24" color="brand.red">
                                        <path
                                            fill="white"
                                            d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667
                      17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197
                      11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008
                      15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845
                      13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281
                      3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594
                      6.03359 13.803 5.6896 13.803 5.33333Z"
                                        />
                                    </Icon>
                                }
                                onClick={() => {
                                    const referralLink = `https://reddoor.stedion.io/#/signup?rCode=${user?.referralCode}`;
                                    navigator.clipboard.writeText(referralLink);
                                    toast({
                                        title: 'Referral link copied to clipboard.',
                                        description: referralLink,
                                        status: 'info',
                                        duration: 9000,
                                        isClosable: true,
                                    });
                                }}
                            >
                                Share
                            </Button>
                        </Center>
                    )}
                    {videoUrls?.length > 0 && (
                        <Center
                            position="absolute"
                            top={0}
                            right={0}
                            bottom={0}
                            left={0}
                        >
                            <IconButton
                                size="lg"
                                variant="solid"
                                aria-label="PlayVideo"
                                fontSize="40px"
                                backgroundColor="brand.video"
                                _hover={{ backgroundColor: 'brand.red' }}
                                borderRadius={6}
                                width={'75px'}
                                icon={
                                    <TriangleUpIcon
                                        transform={'rotate(90deg)'}
                                        color={'white'}
                                        w={7}
                                        h={7}
                                    />
                                }
                                onClick={onVideoOpen}
                            />
                        </Center>
                    )}
                </Box>
                <VStack mt="6" spacing="3" p={6}>
                    <Flex direction="row" w="100%">
                        <Heading size="md" color="brand.green">
                            {title}
                        </Heading>
                        <Spacer />
                        <HStack spacing={1}>
                            <Heading size="sm" color="brand.red">
                                +{points}
                            </Heading>
                            <Heading size="sm" color="brand.green">
                                POINTS
                            </Heading>
                        </HStack>
                    </Flex>
                    <Progress
                        colorScheme="red"
                        size="lg"
                        value={progress}
                        w="100%"
                    />
                    <Text
                        color="gray.400"
                        fontWeight={700}
                        fontSize="medium"
                        w="100%"
                        align={progress === 100 ? 'center' : 'left'}
                    >
                        {completionText}
                    </Text>
                    {showDetails && (
                        <Box w="100%" textAlign="left">
                            <Text color="brand.green" fontWeight={700}>
                                Details:
                            </Text>
                            <Tooltip
                                isDisabled={!isTextLong}
                                label={details}
                                placement="right-start"
                                boxShadow={'lg'}
                                hasArrow={true}
                                width={'auto'}
                                color={'black'}
                                bg="#ffffffa6"
                                sx={{
                                    backdropFilter: 'blur(5px)',
                                    borderRadius: 'md',
                                    color: 'black',
                                    padding: '8px',
                                }}
                            >
                                <Text color="brand.green">
                                    {trimDetailsText(details)}
                                </Text>
                            </Tooltip>
                        </Box>
                    )}
                </VStack>
            </CardBody>
            {progress === 100 ? (
                <CardFooter paddingTop={0} alignSelf={'center'}>
                    <Box w="100%" textAlign="center">
                        <Heading w={200} size="md" color="brand.green">
                            COMPLETED
                        </Heading>
                        <IconButton
                            backgroundColor={'white'}
                            _hover={{
                                backgroundColor: 'white',
                                cursor: 'default',
                            }}
                            isRound={true}
                            variant="solid"
                            disabled={true}
                            aria-label="Completed"
                            fontSize="30px"
                            icon={<CheckCircleIcon color={'brand.red'} />}
                        />
                    </Box>
                </CardFooter>
            ) : (
                <CardFooter>
                    <Flex direction="row" w="100%">
                        <Text
                            color="brand.green"
                            fontWeight={700}
                            cursor="pointer"
                            _hover={{
                                textDecoration: 'underline',
                                color: 'brand.darkGreen',
                            }}
                            _disabled={{
                                color: 'gray.400',
                                cursor: 'not-allowed',
                                textDecoration: 'none',
                            }}
                            onClick={() => setShowDetails(!showDetails)}
                        >
                            See {showDetails ? 'Less' : 'More'}
                        </Text>
                        <Spacer />
                    </Flex>
                </CardFooter>
            )}
            {videoUrls?.length > 0 ? (
                <VideoModal
                    isOpen={isVideoOpen}
                    onClose={onVideoClose}
                    urls={videoUrls}
                    refetch={refetch}
                />
            ) : (
                ''
            )}
        </Card>
    );
};

const SliderItem = (props) => {
    const { isLoading, ...rest } = props;
    const { isOpen, onOpen, onClose } = useDisclosure();

    const moveEffect = keyframes`
        0% { transform: translateY(-100%); }   /* Start above the component */
        50% { transform: translateY(0); }       /* Move to the bottom (original position) */
        100% { transform: translateY(-100%); }  /* Move back to the top */
    `;

    // Define the styles that should apply when isLoading is true
    const loadingStyle = {
        filter: 'blur(1px)', // Apply blur to the entire content
        position: 'relative', // Ensure the moving overlay is positioned correctly
    };

    // Overlay style with moving effect
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, 0.3)', // Semi-transparent overlay
        animation: `${moveEffect} 3s linear infinite`, // Moving effect animation
        pointerEvents: 'none',
        cursor: 'not-allowed', // Allows clicks to pass through the overlay
    };
    return (
        <>
            <Box
                m={2}
                width="100%"
                display={'flex'}
                sx={isLoading ? loadingStyle : {}}
            >
                <CardItem {...rest} onOpen={onOpen} />
                {isLoading && <Box sx={overlayStyle} />}
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <CardItem {...rest} onClose={onClose} showDetails />
                </ModalContent>
            </Modal>
        </>
    );
};

export default SliderItem;
