export const CUSTOMERS_URL = process.env.REACT_APP_CUSTOMERS_API_URL;
export const REWARDS_URL = process.env.REACT_APP_REWARDS_API_URL;

export const LOCATIONS = {
    California: [
        'Concord',
        'Encinitas',
        'Oxnard',
        'San Diego',
        'San Manteo',
        'Sacramento',
        'Temecula',
    ],
    Maryland: ['Gaithersburg'],
    Massachussets: ['Foxboro'],
    Nevada: ['Las Vegas'],
    Oklahoma: ['OKC Chisholm Creek', 'OKC Penn Square'],
    Texas: ['El Paso', 'Fort Worth', 'Plano', 'Southlake'],
    Utah: ['Riverton'],
    Virginia: ['Richmond'],
};

//INFO: We use this constant to define the API_KEY for the useQuery hooks
//      If you need to invalidate any query please use this constant
export const API_KEY = {
    badges: 'customer/:id/all/badges',
    claimed: 'customer/:id/claimed',
    customer: 'customer/:id',
    challenges: 'customer/:id/challenges',
    rewards: 'customer/:id/rewards',
    activities: 'customer/:id/activities',
    coupons: 'customer/:id/coupons',
    emailVerification: 'customer/validate/:code',
};

export default {
    CUSTOMERS_URL,
    REWARDS_URL,
    LOCATIONS,
};
