import * as yup from 'yup';

export const loginSchema = yup.object().shape({
    email: yup
        .string()
        .matches(/\w+@\w+\.\w+/, 'Please provide a valid email format')
        .email('Please provide a valid email format')
        .required('Email is a required field')
        .min(6, 'Email must be at least 6 characters'),
    first_name: yup.string().required('First name is a required field'),
    last_name: yup.string().required('Last name is a required field'),
    phone_number: yup
        .string()
        .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
        .required('A phone number is required'),
    favorite_location: yup.string().optional(),
    staySignedIn: yup.boolean().default(false).optional(),
    email_notification: yup
        .boolean()
        .default(false)
        .test(
            'oneRequired',
            'Please select at least one',
            (values, testContext) => {
                return values || testContext.parent.sms_notification;
            }
        ),
    sms_notification: yup
        .boolean()
        .default(false)
        .test(
            'oneRequired',
            'Please select at least one',
            (values, testContext) => {
                return values || testContext.parent.email_notification;
            }
        ),
    terms_and_conditions: yup.boolean().default(false),
    password: yup
        .string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]/,
            'Password must contain at least one uppercase letter, one number, and one special character'
        )
        .required('Password is a required field'),
    confirm_password: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is a required field'),
});

export const profileUpdateSchema = yup.object().shape({
    email: yup.string().optional(),
    first_name: yup.string().optional().min(1, 'Please enter first name'),
    last_name: yup.string().optional().min(1, 'Please enter last name'),
    phone_number: yup
        .string()
        .matches(/^\+1 \(\d{3}\) \d{3}-\d{4}$/, 'Phone number is not valid')
        .optional(),
    favorite_location: yup.string().optional(),
    email_notification: yup
        .boolean()
        .test(
            'oneRequired',
            'Please select at least one',
            (values, testContext) => {
                return values || testContext.parent.sms_notification;
            }
        ),
    sms_notification: yup
        .boolean()
        .test(
            'oneRequired',
            'Please select at least one',
            (values, testContext) => {
                return values || testContext.parent.email_notification;
            }
        ),
});

export const resetPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password must be at least 8 characters long')
        .matches(
            /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]/,
            'Password must contain at least one uppercase letter, one number, and one special character'
        )
        .required('Password is a required field'),
    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), null], 'Passwords must match')
        .required('Confirm password is a required field'),
});
